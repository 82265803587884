body {
  background-color: #FAFAFA;
}

.slant-header {
  transform:skewx(-7.5deg);
}

ul {
  list-style-type: square;
}

li {
  margin-bottom: 0.5rem;
}