.ScheduleSelect {

}
.ScheduleSelect-calendar-cell {
	height: 2.5rem;
	line-height: 2rem;
	width: 2.5rem;
}
.ScheduleSelect-calendar-day-past,
.ScheduleSelect-calendar-day,
.ScheduleSelect-calendar-day-highlighted,
.ScheduleSelect-calendar-today,
.ScheduleSelect-calendar-future-highlighted,
.ScheduleSelect-calendar-day-outside
{
	margin: 2px;
	border: solid;
	border-width: 2px;
	border-radius: 2rem;
	border-color: rgba(0,0,0,0);
	text-align: center;
	cursor: pointer;
}

.ScheduleSelect-calendar-day-past {
 	color: #999999;
 	cursor: default;
}

.ScheduleSelect-calendar-day-highlighted {
	color: #FFFFFF;
 	background-color: #0084ff;
}

.ScheduleSelect-calendar-future-highlighted {
 	border-color: #0084ff;
 	border-style: dotted;
 	background-color: #FFFFFF;
}

.ScheduleSelect-calendar-today {
	color:rgba(76,167,255,1);
	border-color: #999999;
 	cursor: default;
}

.ScheduleSelect-calendar-day-outside {
	color: #999999;
}

.ScheduleSelect-calendar-day-events {
	background-color: #0000FF;
	cursor: pointer;
}