
.slantbar {
  display: inline-block;
  position: relative;
  height: 1.5rem;
  width: 0.75rem;
  margin-left: 0rem;
  margin-right: 0.1em;
  background-color: rgba(0,100,255,1);
  transform: skewx(-7.5deg);
}

.lightblue {
  background-color: rgba(76,167,255,1);
}
